import React from 'react';
import './massage.css'
const products = [
    { id: 903, title: "Тату надпись", price: 1500, desc: '', city: '' , imgClass: 'card__img-5 tat-1'},
    { id: 904, title: "Тату на запястье", price: 1500, desc: '', city: '' , imgClass: 'card__img-5 tat-2'},
    { id: 905, title: "Тату на шее", price: 1500, desc: '', city: '' , imgClass: 'card__img-5 tat-3'},
    { id: 906, title: "Тату на руке", price: 1500, desc: '', city: '' , imgClass: 'card__img-5 tat-4'},
    { id: 907, title: "Тату на плечо", price: 2500, desc: '', city: '' , imgClass: 'card__img-5 tat-5'},
    { id: 908, title: "Тату рукав", price: 2500, desc: '', city: '' , imgClass: 'card__img-5 tat-6'},
    { id: 909, title: "Тату на ноге", price: 2500, desc: '', city: '' , imgClass: 'card__img-5 tat-7'},
    { id: 910, title: "Тату на спине", price: 2500, desc: '', city: '' , imgClass: 'card__img-5 tat-8'},
    { id: 911, title: "Тату на грудь", price: 2500, desc: '', city: '' , imgClass: 'card__img-5 tat-9'},
    { id: 912, title: "Перекрытие тату", price: 2500, desc: '', city: '' , imgClass: 'card__img-5 tat-10'},
    { id: 913, title: "Коррекция тату", price: 3500, desc: '', city: '' , imgClass: 'card__img-5 tat-11'},
    { id: 914, title: "Заказать тату", price: 1500, desc: '', city: '' , imgClass: 'card__img-5 tat-12'},
];

const tatu = ({title, titlee, handleAddToCart, cartItems, handleRemoveFromCart, handleAddToCartWithNotification}) => {
    return (
        <div className="massage-page">
            <main className="main-block">
                <h1>Сделать татуировку в {titlee}</h1>

                <h2>
                    На официальном сайте представлен актуальный обзор цен и стоимости за работу тату-мастера и сколько стоит сделать татуировку в салоне Волгограда, фото работ татуировщиков по запросу. Мастера со стажем от 15 лет помогут подобрать красивые tattoo для девушек или актуальные мужские эскизы для мужчины.

                    <br />
                    В прайсе минимальное описание, конечную стоимость татуировки, от самой маленькой черно-белой надписи, до большого цветного рисунка с узорами и расценки сложных эскизов уточняйте у мастера.

                    <br />
                    Дешевле или дороже, конечная цена за выполненную работу будет зависеть от её сложности. Реализм и фотографическая точность при нанесении изображения в кельтском стиле или биомеханике, с фантастическими сюжетами или трёхмерными рисунками и использованием несколько цветов.
                </h2>
            </main>

            <main className="thride-block">
            <h1>Услуги Тату в в {titlee}</h1>

            <div className="thride-block__cards">
                {products.map((product) => (
                    <div key={product.id} className="thride-block__card">
                        <div className={product.imgClass}></div>
                        <h1 className="card__title">{product.title}</h1>
                        <h2 className="card__subtitle">{product.desc}</h2>
                        <h2 className="card__subtitle">{product.price}</h2>
                        <h2 className="card__subtitle">{product.city}</h2>
                        <button className="card__btn" onClick={() => handleAddToCartWithNotification(product)}>Добавить в корзину</button>
                    </div>
                ))}
            </div>
        </main>

        </div>
    );
}

export default tatu;