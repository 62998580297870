import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import './Header.css';
import BurgerMenu from './BurgerMenu';
import SearchPage from './SearchPage';


const tags = [
    { label: 'Массаж', link: 'massage' },
    { label: 'Спа', link: 'spa' },
    { label: 'Для женщин', link: 'massage' },
    { label: 'Для мужчин', link: 'massage' },
    { label: 'Абонемент', link: 'abon' },
    { label: 'Курсы', link: 'courses' },
    { label: 'Обучение', link: 'obuc' },
];


const Header = ({cartItems, handleAddToCart, handleRemoveFromCart, products, handleAddToCartWithNotification }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [filters, setFilters] = useState({
        duration: [],
        serviceType: [],
        forWhom: [],
        course: [],
        priceFrom: '',
        priceTo: '',
        medicalEducation: '',
        specialOccasion: [],
    });



    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleTagClick = (tag) => {
        navigate(`/${tag.link}`);
        setSearchQuery(tag.label);
        setIsSearchOpen(false);
    };

    const handleFilterChange = (event) => {
        const { name, value, checked } = event.target;
        if (name === 'priceFrom' || name === 'priceTo') {
            setFilters({ ...filters, [name]: value });
        } else if (name === 'medicalEducation') {
            setFilters({ ...filters, medicalEducation: value });
        } else if (name === 'specialOccasion') {
            if (checked) {
                setFilters({ ...filters, specialOccasion: [...filters.specialOccasion, value] });
            } else {
                setFilters({ ...filters, specialOccasion: filters.specialOccasion.filter((item) => item !== value) });
            }
        } else {
            if (checked) {
                setFilters({ ...filters, [name]: [...filters[name], value] });
            } else {
                setFilters({ ...filters, [name]: filters[name].filter((item) => item !== value) });
            }
        }
    };

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        console.log('Search submitted:', searchQuery);
    };

    const handleSearchClick = () => {
        setIsSearchOpen(true);
    };

    const handleCloseSearch = () => {
        setIsSearchOpen(false);
    };

    const handleFilterSubmit = () => {
        setIsSearchOpen(false);
    };

    const handleClearFilters = () => {
        setFilters({
            duration: [],
            serviceType: [],
            forWhom: [],
            course: [],
            priceFrom: '',
            priceTo: '',
            medicalEducation: '',
            specialOccasion: [],
        });
        setSearchQuery('');
    };

    const filteredProducts = products.filter((product) => {
        const matchesSearchQuery = product.title.toLowerCase().includes(searchQuery.toLowerCase());
        const matchesDuration = !filters.duration.length || filters.duration.includes(product.duration);
        const matchesServiceType = !filters.serviceType.length || filters.serviceType.includes(product.serviceType);
        const matchesForWhom = !filters.forWhom.length || filters.forWhom.includes(product.forWhom);
        const matchesCourse = !filters.course.length || filters.course.includes(product.course);
        const matchesPriceFrom = !filters.priceFrom || parseFloat(product.price) >= parseFloat(filters.priceFrom);
        const matchesPriceTo = !filters.priceTo || parseFloat(product.price) <= parseFloat(filters.priceTo);
        const matchesMedicalEducation = filters.medicalEducation === '' || filters.medicalEducation.includes(product.medicalEducation);
        const matchesSpecialOccasion = !filters.specialOccasion.length || filters.specialOccasion.includes(product.specialOccasion);

        return matchesSearchQuery && matchesDuration && matchesServiceType && matchesForWhom && matchesCourse && matchesPriceFrom && matchesPriceTo && matchesMedicalEducation && matchesSpecialOccasion;
    });


    const links = [
        { href: '/', label: 'О салоне' },
        {
            href: '/massage', label: 'Массаж', subLinks: [
                { href: '/massage', label: 'Массаж цена' },
                { href: '/massage', label: 'Массаж для женщины' },
                { href: '/massage', label: 'Массаж для мужчины' },
                { href: '/massageTwo', label: 'Массаж для двоих' },
                { href: '/massageface', label: 'Массаж лица' },
                { href: '/massage', label: 'Массаж тела' },
                { href: '/massagechildren', label: 'Детский массаж' },
                { href: '/massageanti', label: 'Антицеллюлитный массаж' },
                { href: '/massagelpg', label: 'LPG массаж' },
                { href: '/abon', label: 'Абонементы' },
            ]
        },
        { href: '/cosmet', label: 'Косметология' },
        { href: '/spa', label: 'Спа', subLinks: [
                { href: '/spa', label: 'Спа программы' },
                { href: '/spatwo', label: 'Спа для двоих' },
                { href: '/spa', label: 'Спа для женщины' },
                { href: '/spa', label: 'Спа для мужчины' },
                { href: '/massage', label: 'Спа массаж' },
            ] },
        { href: '/tatu', label: 'Тату и татуаж' },
        {
            href: '/obuc', label: 'Обучение', subLinks: [
                { href: '/obuc', label: 'Обучение массаже' },
                { href: '/obuct', label: 'Обучение тату' },
                { href: '/obucc', label: 'Обучение косметологии' },
            ]
        },
        { href: '/podarok', label: 'Подарочные сертификаты' },
    ];
    const navigate = useNavigate();

    const handleViewCart = () => {
        navigate('/cart');
    };

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSearchSub = (e) => {
        e.preventDefault();
        navigate(`/search?query=${searchQuery}`);
    };



    return (
        <header className="header">

            <div>
                <nav>
                    <select onchange="location.href=this.value" className="region">
                        <option value="">Выберите город</option>
                        <option value="https://alexestetica.ru/abakan">Абакан</option>
                        <option value="https://alexestetica.ru/adler">Адлер</option>
                        <option value="https://alexestetica.ru/achinsk">Ачинск</option>
                        <option value="https://alexestetica.ru/almetevsk">Альметьевск</option>
                        <option value="https://alexestetica.ru/angarsk">Ангарск</option>
                        <option value="https://alexestetica.ru/arkhangelsk">Архангельск</option>
                        <option value="https://alexestetica.ru/astrakhan">Астрахань</option>
                        <option value="https://alexestetica.ru/balakovo">Балаково</option>
                        <option value="https://alexestetica.ru/balashikha">Балашиха</option>
                        <option value="https://alexestetica.ru/">Волгоград</option>
                        <option value="https://alexestetica.ru/volzhskiy">Волжский</option>
                        <option value="https://alexestetica.ru/krasnodar">Краснодар</option>
                        <option value="https://alexestetica.ru/krasnaya-polyana">Красная поляна</option>
                        <option value="https://alexestetica.ru/moskva">Москва</option>
                        <option value="https://alexestetica.ru/mihaylovka">Михайловка</option>
                        <option value="https://alexestetica.ru/sochi">Сочи</option>
                        <option value="https://alexestetica.ru/spb">Санкт-Петербург</option>
                    </select>
                    <a href='tel:+79939775035' className='number'>+7 (993)-997-50-35</a>
                </nav>
                <nav>

                    <NavLink className="logo" to="/"><a ></a></NavLink>

                    <ul className="nav-left">
                        <li>
                        <NavLink to="/"><a >О салоне</a></NavLink>
                        </li>
                        <li className="dropdown">
                        <NavLink to="/massage"><a >Массаж</a></NavLink>
                            <ul className="dropdown-content">
                                <NavLink to="/massage"><li><a >Массаж цена</a></li></NavLink>
                                <NavLink to="/massage"><li><a >Массаж для женщины</a></li></NavLink>
                                <NavLink to="/massage"><li><a >Массаж для мужчины</a></li></NavLink>
                                <NavLink to="/massageTwo"><li><a >Массаж для двоих</a></li></NavLink>
                                <NavLink to="/massageface"><li><a >Массаж лица</a></li></NavLink>
                                <NavLink to="/massage"><li><a >Массаж тела</a></li></NavLink>
                                <NavLink to="/massagechildren"><li><a >Детский массаж</a></li></NavLink>
                                <NavLink to="/massageanti"><li><a >Антицеллюлитный массаж</a></li></NavLink>
                                <NavLink to="/massagelpg"><li><a >LPG массаж</a></li></NavLink>
                                <NavLink to="/abon"><li><a >Абонементы</a></li></NavLink>
                            </ul>
                        </li>
                        <NavLink to="/cosmet"><li><a >Косметология</a></li></NavLink>
                        <li className="dropdown">
                            <a >Спа</a>
                            <ul className="dropdown-content">
                            <NavLink to="/spa"><li><a >Спа программы</a></li></NavLink>
                            <NavLink to="/spatwo"><li><a >Спа для двоих</a></li></NavLink>
                            <NavLink to="/spa"><li><a >Спа для женщины</a></li></NavLink>
                            <NavLink to="/spa"><li><a >Спа для мужчины</a></li></NavLink>
                            <NavLink to="/massage"><li><a >Спа массаж</a></li></NavLink>
                            </ul>
                        </li>
                        <NavLink to="/tatu"><li><a >Тату</a></li></NavLink>
                        <li className="dropdown">
                            <a >Обучение</a>
                            <ul className="dropdown-content">
                            <NavLink to="/obuc"><li><a >Обучение массажу</a></li></NavLink>
                            <NavLink to="/obucc"><li><a >Обучение косметологии</a></li></NavLink>
                            <NavLink to="/obuct"><li><a >Обучение тату и татуаж</a></li></NavLink>
                            </ul>
                        </li>
                        <NavLink to="/podarok"><li><a>Подарочные сертификаты</a></li></NavLink>
                    </ul>



                    <div className={`search-overlay ${isSearchOpen ? 'open' : ''}`}>
                        <button type="button" className="close-button" onClick={handleCloseSearch}>X</button>
                        <div className="filter-section">
                            <label><div className="logo-sect"></div></label>
                            <label>
                                <input
                                    type="text"
                                    className="search-input"
                                    placeholder="Search products..."
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    onClick={handleSearchClick}
                                />
                            </label>
                            <div className="tag-container">
                                {tags.map((tag, index) => (
                                    <button key={index} className="tag" onClick={() => handleTagClick(tag)}>
                                        {tag.label}
                                    </button>
                                ))}
                            </div>
                            <h2 className='kkk'>Длительность процедуры:</h2>
                            <label className='kkk'>
                                <input
                                    type="checkbox"
                                    name="duration"
                                    value="1 час"
                                    checked={filters.duration.includes('1 час')}
                                    onChange={handleFilterChange}
                                />
                                1 час
                            </label>
                            <label className='kkk'>
                                <input
                                    type="checkbox"
                                    name="duration"
                                    value="1.30 часа"
                                    checked={filters.duration.includes('1.30 часа')}
                                    onChange={handleFilterChange}
                                />
                                1 час 30 минут
                            </label>
                            <label className='kkk'>
                                <input
                                    type="checkbox"
                                    name="duration"
                                    value="2 часа"
                                    checked={filters.duration.includes('2 часа')}
                                    onChange={handleFilterChange}
                                />
                                2 часа
                            </label>
                            <label className='kkk'>
                                <input
                                    type="checkbox"
                                    name="duration"
                                    value="3 часа"
                                    checked={filters.duration.includes('3 часа')}
                                    onChange={handleFilterChange}
                                />
                                3 часа
                            </label>

                            <h2 className='kkk'>Вид услуги:</h2>
                            <label className='kkk'>
                                <input
                                    type="checkbox"
                                    name="serviceType"
                                    value="Массаж"
                                    checked={filters.serviceType.includes('Массаж')}
                                    onChange={handleFilterChange}
                                />
                                Массаж
                            </label>
                            <label className='kkk'>
                                <input
                                    type="checkbox"
                                    name="serviceType"
                                    value="Спа"
                                    checked={filters.serviceType.includes('Спа')}
                                    onChange={handleFilterChange}
                                />
                                Спа
                            </label>
                            <label className='kkk'>
                                <input
                                    type="checkbox"
                                    name="course"
                                    value="Курсы"
                                    checked={filters.course.includes('Курсы')}
                                    onChange={handleFilterChange}
                                />
                                Курсы
                            </label>

                            <h2 className='kkk'>Для кого:</h2>
                            <label className='kkk'>
                                <input
                                    type="checkbox"
                                    name="forWhom"
                                    value="Для женщин"
                                    checked={filters.forWhom.includes('Для женщин')}
                                    onChange={handleFilterChange}
                                />
                                Для женщин
                            </label>
                            <label className='kkk'>
                                <input
                                    type="checkbox"
                                    name="forWhom"
                                    value="Для мужчин"
                                    checked={filters.forWhom.includes('Для мужчин')}
                                    onChange={handleFilterChange}
                                />
                                Для мужчин
                            </label>
                            <label className='kkk'>
                                <input
                                    type="checkbox"
                                    name="forWhom"
                                    value="Для детей"
                                    checked={filters.forWhom.includes('Для детей')}
                                    onChange={handleFilterChange}
                                />
                                Для детей
                            </label>
                            <label className='kkk'>
                                <input
                                    type="checkbox"
                                    name="forWhom"
                                    value="Для двоих"
                                    checked={filters.forWhom.includes('Для двоих')}
                                    onChange={handleFilterChange}
                                />
                                Для двоих
                            </label>

                            <label className='kkk'>
                                <input
                                    type="radio"
                                    name="medicalEducation"
                                    value="true"
                                    checked={filters.medicalEducation === 'true'}
                                    onChange={handleFilterChange}
                                />
                                С медицинским образованием
                            </label>
                            <label className='kkk'>
                                <input
                                    type="radio"
                                    name="medicalEducation"
                                    value="false"
                                    checked={filters.medicalEducation === 'false'}
                                    onChange={handleFilterChange}
                                />
                                Без медицинского образования
                            </label>
                            <h2 className='kkk'>Специальные случаи:</h2>
                            <label className='kkk'>
                                <input
                                    type="checkbox"
                                    name="specialOccasion"
                                    value="8 марта"
                                    checked={filters.specialOccasion.includes('8 марта')}
                                    onChange={handleFilterChange}
                                />
                                8 марта
                            </label>
                            <label className='kkk'>
                                <input
                                    type="checkbox"
                                    name="specialOccasion"
                                    value="14 февраля"
                                    checked={filters.specialOccasion.includes('14 февраля')}
                                    onChange={handleFilterChange}
                                />
                                14 февраля
                            </label>
                            <label className='kkk'>
                                <input
                                    type="checkbox"
                                    name="specialOccasion"
                                    value="23 февраля"
                                    checked={filters.specialOccasion.includes('23 февраля')}
                                    onChange={handleFilterChange}
                                />
                                23 февраля
                            </label>
                            <label className='kkk'>
                                <input
                                    type="checkbox"
                                    name="specialOccasion"
                                    value="Новый год"
                                    checked={filters.specialOccasion.includes('Новый год')}
                                    onChange={handleFilterChange}
                                />
                                Новый год
                            </label>
                            <label className='kkk'>
                                <input
                                    type="checkbox"
                                    name="specialOccasion"
                                    value="для родителей"
                                    checked={filters.specialOccasion.includes('для родителей')}
                                    onChange={handleFilterChange}
                                />
                                Для родителей
                            </label>
                            <label className='kkk'>
                                <input
                                    type="checkbox"
                                    name="specialOccasion"
                                    value="День Рождение"
                                    checked={filters.specialOccasion.includes('День Рождение')}
                                    onChange={handleFilterChange}
                                />
                                День Рождение
                            </label>


                            <h2 className='kkk'>Цена:</h2>
                            <input className='kkk'
                                   type="text"
                                   name="priceFrom"
                                   placeholder="От"
                                   value={filters.priceFrom}
                                   onChange={handleFilterChange}
                            />
                            <input className='kkk'
                                   type="text"
                                   name="priceTo"
                                   placeholder="До"
                                   value={filters.priceTo}
                                   onChange={handleFilterChange}
                            />



                            <div className='btns-fil'>
                                <button type="button" onClick={handleFilterSubmit} className='prim'>Применить</button>
                                <button type="button" onClick={handleClearFilters} className='ochist'>Очистить</button>
                            </div>
                        </div>

                        <div className="search-results">
                            {filteredProducts.map((product) => (
                                <div key={product.id} className="search-result__item">
                                    <img className={product.imgClass} />
                                    <div>
                                        <div>
                                            <h1>{product.title}</h1>
                                            <p>{product.price}</p>
                                            <p>{product.duration}</p>
                                        </div>
                                        <button onClick={() => handleAddToCartWithNotification(product)}>Добавить в корзину</button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='forms'>
                        <form className="search-form" onSubmit={handleSearchSubmit}>
                            <div className="header-icons_filters">
                                <img                           onChange={handleSearchChange}
                                                               onClick={handleSearchClick} src='icons8-tune-50.png'/>
                            </div>

                            <div className='class-icon-wrap'><button className="cart-icon" onClick={handleViewCart}> </button></div>
                            <BurgerMenu links={links} />
                            {/*<p><strong>Поиск</strong> </p>*/}
                        </form>

                        <form className="search-form" onSubmit={handleSearchSub}>
                            <input className="search-inputs"
                                   type="text"
                                   value={searchQuery}
                                   onChange={handleSearch}
                                   placeholder="Поиск страниц..."
                            />
                        </form>
                    </div>
                </nav>
            </div>
        </header>
    );
};

export default Header;
