import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import emailjs from 'emailjs-com';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import HomePage from './components/main-page/HomePage';
import Massage from './components/pages/massage-page';
import MassageTwo from './components/pages/massage-two';
import Massageface from './components/pages/massage-face';
import Massageanti from './components/pages/massage-anti';
import Massagelpg from './components/pages/massage-lpg';
import Massagechildren from './components/pages/massage-children';
import Obuc from './components/pages/obuchenie';
import Cosmet from './components/pages/cosmetology';
import Podarok from './components/pages/podarok';
import Tatu from './components/pages/taty';
import Abon from './components/pages/abonym';
import Obucc from './components/pages/obuc-cos';
import Obuct from './components/pages/obuc-tat';
import Breadcrumbs from './components/pages/Breadcrumbs';
import Cart from "./components/pages/cart";
import Polyt from "./components/pages/polyt";
import Spa from "./components/pages/spa";
import SpaTwo from "./components/pages/SpaTwo";
import SearchPage from "./components/header/SearchPage";


// const products = [
//   {
//     id: 1,
//     title: "Массаж для женщин",
//     duration: "1 час", // Длительность процедуры
//     serviceType: "Массаж", // Вид услуги
//     forWhom: "Для женщин", // Для кого
//     course: "true", // Курсы (если продукт является курсом)
//     price: "1500", // Цена
//     medicalEducation: "true", // Медицинское образование (true/false)
//     specialOccasion: "8 марта", // Специальные случаи (например, "8 марта", "14 февраля" и т.д.)
//     imgClass: "product-img-class" // Класс для изображения (если есть)
//   },
//   {
//     id: 2,
//     title: "Спа для двоих",
//     duration: "2 часа",
//     serviceType: "Спа",
//     forWhom: "Для двоих",
//     course: "false",
//     price: "3000",
//     medicalEducation: "false",
//     specialOccasion: "День Рождение",
//     imgClass: "product-img-class"
//   },
//   // Другие продукты...
// ];
// Описание возможных значений для каждого фильтра (не оставляйте поля пустыми если в них нечего не вписать, к примеру, если у вас карточка в поиске конкретно массаж, то есть не курсы массажа, вы не пишете в products - course:''. Объясняю почему, ваш поиск будет все равно отображать эту карточку при выборе course, т к он пытается понять надо или не надо ему обрабатывать этот запрос)
// Длительность процедуры (duration):
// Возможные значения: "1 час", "1.30 часа", "2 часа", "3 часа" и другие.
//     Пример: duration: "1 час"
// Вид услуги (serviceType):
// Возможные значения: "Массаж", "Спа", "Косметология" и другие.
//     Пример: serviceType: "Массаж"
// Для кого (forWhom):
// Возможные значения: "Для женщин", "Для мужчин", "Для детей", "Для двоих".
//     Пример: forWhom: "Для женщин"
// Курсы (course):
// Возможные значения: "true" (если продукт является курсом), "false" (если продукт не является курсом).
// Пример: course: "true"
// Цена (price):
// Значение: строка, представляющая цену продукта. Пример: price: "1500"
// Медицинское образование (medicalEducation):
// Возможные значения: "true" (если требуется медицинское образование), "false" (если не требуется медицинское образование).
// Пример: medicalEducation: "true"
// Специальные случаи (specialOccasion):
// Возможные значения: "8 марта", "14 февраля", "23 февраля", "Новый год", "для родителей", "День Рождение" и другие.
//     Пример: specialOccasion: "8 марта"









const App = () => {
  const [cartItems, setCartItems] = useState([]);
  const products = [

    { id: 9999, title: 'Сертификат ', price: '1p.', duration: '', imgClass: 'card__img-search-1', serviceType:'', forWhom: '', medicalEducation: false, specialOccasion: [] },
    { id: 9998, title: 'Сертификат на массаж', price: '1p', duration: '', imgClass: 'card__img-search-2', serviceType:'', forWhom: '', medicalEducation: false, specialOccasion: [] },
    { id: 9997, title: 'Сертификат спа', price: '1p.', duration: '', imgClass: 'card__img-search-3', serviceType:'', forWhom: '', medicalEducation: false, specialOccasion: [] },
    { id: 9996, title: 'Подарочный сертификат онлайн ', price: '1p.', duration: '', imgClass: 'card__img-search-4', serviceType:'', forWhom: '', medicalEducation: false, specialOccasion: [] },
    { id: 9995, title: 'Подарочный сертификат на массаж', price: '1p.', duration: '', imgClass: 'card__img-search-5', serviceType:'', forWhom: '', medicalEducation: false, specialOccasion: [] },
    { id: 9994, title: 'Курс массажа', price: '2p.', duration: '', imgClass: 'card__img-search-6', serviceType:'Курсы и обучение', forWhom: '', medicalEducation: true, specialOccasion: ['8 марта', 'Новый год'] },
    { id: 9993, title: 'Курс косметологии', price: '2p.',course: 'Курсы', duration: '', imgClass: 'card__img-search-7', serviceType:'Курсы и обучение', forWhom: '', medicalEducation: false, specialOccasion: ['14 февраля', 'День Рождение'] },


    { id: 1, title: 'Сертификат ', price: '1p.', duration: '', imgClass: 'card__img-search-1', serviceType:'', forWhom: ''},
    { id: 2, title: 'Сертификат на массаж', price: '1p', duration: '', imgClass: 'card__img-search-2', serviceType:'', forWhom: ''},
    { id: 3, title: 'Сертификат спа', price: '1p.', duration: '', imgClass: 'card__img-search-3', serviceType:'', forWhom: ''},
    { id: 4, title: 'Подарочный сертификат онлайн ', price: '1p.', duration: '', imgClass: 'card__img-search-4', serviceType:'', forWhom: ''},
    { id: 5, title: 'Подарочный сертификат на массаж', price: '1p.', duration: '', imgClass: 'card__img-search-5', serviceType:'', forWhom: ''},
    { id: 6, title: 'Подарочный сертификат для женщины ', price: '1p.', duration: '', imgClass: 'card__img-search-6', serviceType:'', forWhom: ''},
    { id: 7, title: 'Подарочный сертификат для мужчины', price: '1p.', duration: '', imgClass: 'card__img-search-7', serviceType:'', forWhom: ''},
    { id: 8, title: 'Подарочный сертификат мужчине', price: '1p.', duration: '', imgClass: 'card__img-search-8', serviceType:'', forWhom: ''},
    { id: 9, title: 'Подарочный сертификат женщине', price: '1p.', duration: '', imgClass: 'card__img-search-9', serviceType:'', forWhom: ''},
    { id: 10, title: 'Подарочный сертификат родителям ', price: '1p.', duration: '', imgClass: 'card__img-search-10', serviceType:'', forWhom: ''},
    { id: 11, title: 'Подарочный сертификат на день рождения ', price: '1p.', duration: '', imgClass: 'card__img-search-11', serviceType:'', forWhom: ''},
    { id: 12, title: 'Подарочный сертификат на 8 марта', price: '1p.', duration: '', imgClass: 'card__img-search-12', serviceType:'', forWhom: ''},
    { id: 13, title: 'Подарочный сертификат на 23 февраля', price: '1p.', duration: '', imgClass: 'card__img-search-13', serviceType:'', forWhom: ''},
    { id: 14, title: 'Подарочный сертификат на новый год ', price: '1p.', duration: '', imgClass: 'card__img-search-14', serviceType:'', forWhom: ''},
    { id: 15, title: 'Сертификат на впечатление ', price: '1p.', duration: '', imgClass: 'card__img-search-15', serviceType:'', forWhom: ''},
    { id: 16, title: 'Электронный сертификат ', price: '1p.', duration: '', imgClass: 'card__img-search-16', serviceType:'', forWhom: ''},
    { id: 17, title: 'Подарочный сертификат в Спа', price: '1p.', duration: '', imgClass: 'card__img-search-17', serviceType:'', forWhom: ''},
    { id: 18, title: 'Спа программы', price: '1p.', duration: '', imgClass: 'card__img-search-18', serviceType:'', forWhom: ''},
    { id: 19, title: 'Спа процедуры', price: '1p.', duration: '', imgClass: 'card__img-search-19', serviceType:'', forWhom: ''},
    { id: 20, title: 'Спа для двоих', price: '1p.', duration: '', imgClass: 'card__img-search-20', serviceType:'', forWhom: ''},
    { id: 21, title: 'Спа двоим', price: '1p.', duration: '', imgClass: 'card__img-search-21', serviceType:'', forWhom: ''},
    { id: 22, title: 'Спа двое', price: '1p.', duration: '', imgClass: 'card__img-search-22', serviceType:'', forWhom: ''},
    { id: 23, title: 'Спа для женщины ', price: '1p.', duration: '', imgClass: 'card__img-search-23', serviceType:'', forWhom: ''},
    { id: 24, title: 'Спа для мужчины', price: '1p.', duration: '', imgClass: 'card__img-search-24', serviceType:'', forWhom: ''},
    { id: 25, title: 'Массаж', price: '1p.', duration: '', imgClass: 'card__img-search-25', serviceType:'', forWhom: ''},
    { id: 26, title: 'Массаж релакс', price: '1p.', duration: '', imgClass: 'card__img-search-26', serviceType:'', forWhom: ''},
    { id: 27, title: 'Массаж для двоих', price: '1p.', duration: '', imgClass: 'card__img-search-27', serviceType:'', forWhom: ''},
    { id: 28, title: 'Массаж двоим', price: '1p.', duration: '', imgClass: 'card__img-search-28', serviceType:'', forWhom: ''},
    { id: 29, title: 'Массаж для мужчины', price: '1p.', duration: '', imgClass: 'card__img-search-29', serviceType:'', forWhom: ''},
    { id: 30, title: 'Массаж для женщины', price: '1p.', duration: '', imgClass: 'card__img-search-30', serviceType:'', forWhom: ''},
    { id: 31, title: 'Массаж женщине', price: '1p.', duration: '', imgClass: 'card__img-search-31', serviceType:'', forWhom: ''},
    { id: 32, title: 'Массаж мужчине ', price: '1p.', duration: '', imgClass: 'card__img-search-32', serviceType:'', forWhom: ''},
    { id: 33, title: 'массаж гуаша', price: '1p.', duration: '', imgClass: 'card__img-search-33', serviceType:'', forWhom: ''},
    { id: 34, title: 'Массаж паре', price: '1p.', duration: '', imgClass: 'card__img-search-34', serviceType:'', forWhom: ''},
    { id: 35, title: 'Массаж лица ', price: '1p.', duration: '', imgClass: 'card__img-search-35', serviceType:'', forWhom: ''},
    { id: 36, title: 'Массаж тела', price: '1p.', duration: '', imgClass: 'card__img-search-36', serviceType:'', forWhom: ''},
    { id: 37, title: 'Лимфодренажный массаж  ', price: '1p.', duration: '', imgClass: 'card__img-search-37', serviceType:'', forWhom: ''},
    { id: 38, title: 'Классический массаж', price: '1p.', duration: '', imgClass: 'card__img-search-38', serviceType:'', forWhom: ''},
    { id: 39, title: 'Пластический массаж ', price: '1p.', duration: '', imgClass: 'card__img-search-39', serviceType:'', forWhom: ''},
    { id: 40, title: 'Буккальный массаж ', price: '1p.', duration: '', imgClass: 'card__img-search-40', serviceType:'', forWhom: ''},
    { id: 41, title: 'Точечный массаж ', price: '1p.', duration: '', imgClass: 'card__img-search-41', serviceType:'', forWhom: ''},
    { id: 42, title: 'Японский массаж ', price: '1p.', duration: '', imgClass: 'card__img-search-42', serviceType:'', forWhom: ''},
    { id: 43, title: 'Стоунтерапия', price: '1p.', duration: '', imgClass: 'card__img-search-43', serviceType:'', forWhom: ''},
    { id: 44, title: 'Массаж горячими камнями', price: '1p.', duration: '', imgClass: 'card__img-search-44', serviceType:'', forWhom: ''},
    { id: 45, title: 'Вакуумный массаж', price: '1p.', duration: '', imgClass: 'card__img-search-45', serviceType:'', forWhom: ''},
    { id: 46, title: 'Вакуумно-роликовый массаж', price: '1p.', duration: '', imgClass: 'card__img-search-46', serviceType:'', forWhom: ''},
    { id: 47, title: 'Кавитация ', price: '1p.', duration: '', imgClass: 'card__img-search-47', serviceType:'', forWhom: ''},
    { id: 48, title: 'РФ лифтинг', price: '1p.', duration: '', imgClass: 'card__img-search-48', serviceType:'', forWhom: ''},
    { id: 49, title: 'Антицеллюлитный массаж ', price: '1p.', duration: '', imgClass: 'card__img-search-49', serviceType:'', forWhom: ''},
    { id: 50, title: 'Медовый массаж ', price: '1p.', duration: '', imgClass: 'card__img-search-50', serviceType:'', forWhom: ''},
    { id: 51, title: 'Релаксирующий массаж', price: '1p.', duration: '', imgClass: 'card__img-search-51', serviceType:'', forWhom: ''},
    { id: 52, title: 'Спортивный массаж ', price: '1p.', duration: '', imgClass: 'card__img-search-52', serviceType:'', forWhom: ''},
    { id: 53, title: 'Лечебный массаж ', price: '1p.', duration: '', imgClass: 'card__img-search-53', serviceType:'', forWhom: ''},
    { id: 54, title: 'Оздоровительный массаж', price: '1p.', duration: '', imgClass: 'card__img-search-54', serviceType:'', forWhom: ''},
    { id: 55, title: 'Массаж спины', price: '1p.', duration: '', imgClass: 'card__img-search-55', serviceType:'', forWhom: ''},
    { id: 56, title: 'Антистрессовый массаж', price: '1p.', duration: '', imgClass: 'card__img-search-56', serviceType:'', forWhom: ''},
    { id: 57, title: 'Массаж для похудения ', price: '1p.', duration: '', imgClass: 'card__img-search-57', serviceType:'', forWhom: ''},
    { id: 58, title: 'Тайский массаж ', price: '1p.', duration: '', imgClass: 'card__img-search-58', serviceType:'', forWhom: ''},
    { id: 59, title: 'Испанский массаж', price: '1p.', duration: '', imgClass: 'card__img-search-59', serviceType:'', forWhom: ''},
    { id: 60, title: 'Креольский массаж', price: '1p.', duration: '', imgClass: 'card__img-search-60', serviceType:'', forWhom: ''},
    { id: 61, title: 'Балийский массаж', price: '1p.', duration: '', imgClass: 'card__img-search-61', serviceType:'', forWhom: ''},
    { id: 62, title: 'Арома массаж', price: '1p.', duration: '', imgClass: 'card__img-search-62', serviceType:'', forWhom: ''},
    { id: 63, title: 'Массаж в 4-ре руки  ', price: '1p.', duration: '', imgClass: 'card__img-search-63', serviceType:'', forWhom: ''},
    { id: 64, title: 'Индийский массаж', price: '1p.', duration: '', imgClass: 'card__img-search-64', serviceType:'', forWhom: ''},
    { id: 65, title: 'Тибетский массаж', price: '1p.', duration: '', imgClass: 'card__img-search-65', serviceType:'', forWhom: ''},
    { id: 66, title: 'Маслянный массаж ', price: '1p.', duration: '', imgClass: 'card__img-search-66', serviceType:'', forWhom: ''},
    { id: 67, title: 'Расслабляющий массаж ', price: '1p.', duration: '', imgClass: 'card__img-search-67', serviceType:'', forWhom: ''},
    { id: 68, title: 'Стоун-массаж ', price: '1p.', duration: '', imgClass: 'card__img-search-68', serviceType:'', forWhom: ''},
    { id: 69, title: 'Гавайский массаж', price: '1p.', duration: '', imgClass: 'card__img-search-69', serviceType:'', forWhom: ''},
    { id: 70, title: 'Массаж Шиацу', price: '1p.', duration: '', imgClass: 'card__img-search-70', serviceType:'', forWhom: ''},
    { id: 71, title: 'Турецкий массаж', price: '1p.', duration: '', imgClass: 'card__img-search-71', serviceType:'', forWhom: ''},
    { id: 72, title: 'Пенный массаж', price: '1p.', duration: '', imgClass: 'card__img-search-72', serviceType:'', forWhom: ''},
    { id: 73, title: 'Аюрведический массаж', price: '1p.', duration: '', imgClass: 'card__img-search-73', serviceType:'', forWhom: ''},
    { id: 74, title: 'Массаж стоп', price: '1p.', duration: '', imgClass: 'card__img-search-74', serviceType:'', forWhom: ''},
    { id: 75, title: 'Foot массаж', price: '1p.', duration: '', imgClass: 'card__img-search-75', serviceType:'', forWhom: ''},
    { id: 76, title: 'Массаж головы ', price: '1p.', duration: '', imgClass: 'card__img-search-76', serviceType:'', forWhom: ''},
    { id: 77, title: 'Массаж рук', price: '1p.', duration: '', imgClass: 'card__img-search-77', serviceType:'', forWhom: ''},
    { id: 78, title: 'Массаж ног', price: '1p.', duration: '', imgClass: 'card__img-search-78', serviceType:'', forWhom: ''},
    { id: 79, title: 'Общий массаж', price: '1p.', duration: '', imgClass: 'card__img-search-79', serviceType:'', forWhom: ''},
    { id: 80, title: 'Детский массаж ', price: '1p.', duration: '', imgClass: 'card__img-search-80', serviceType:'', forWhom: ''},
    { id: 81, title: 'LPG массаж', price: '1p.', duration: '', imgClass: 'card__img-search-81', serviceType:'', forWhom: ''},
    { id: 82, title: 'Прессотерапия', price: '1p.', duration: '', imgClass: 'card__img-search-82', serviceType:'', forWhom: ''},
    { id: 83, title: 'Баночный массаж ', price: '1p.', duration: '', imgClass: 'card__img-search-83', serviceType:'', forWhom: ''},
    { id: 84, title: 'Массаж бамбуковыми палочками ', price: '1p.', duration: '', imgClass: 'card__img-search-84', serviceType:'', forWhom: ''},
    { id: 85, title: 'Обёртывания', price: '1p.', duration: '', imgClass: 'card__img-search-85', serviceType:'', forWhom: ''},
    { id: 86, title: 'Спа массаж', price: '1p.', duration: '', imgClass: 'card__img-search-86', serviceType:'', forWhom: ''},
    { id: 87, title: 'Спа двоим', price: '1p.', duration: '', imgClass: 'card__img-search-87', serviceType:'', forWhom: ''},
    { id: 88, title: 'Курсы массажа', price: '1p.', duration: '', imgClass: 'card__img-search-88', serviceType:'', forWhom: ''},
    { id: 89, title: 'Обучение массажу', price: '1p.', duration: '', imgClass: 'card__img-search-89', serviceType:'', forWhom: ''},
    { id: 90, title: 'курсы Классический массаж ', price: '1p.', duration: '', imgClass: 'card__img-search-90', serviceType:'', forWhom: ''},
    { id: 91, title: 'курсы Антицеллюлитный массаж ', price: '1p.', duration: '', imgClass: 'card__img-search-91', serviceType:'', forWhom: ''},
    { id: 92, title: 'курсы Лимфодренажный массаж', price: '1p.', duration: '', imgClass: 'card__img-search-92', serviceType:'', forWhom: ''},
    { id: 93, title: 'курсы Спортивный массаж', price: '1p.', duration: '', imgClass: 'card__img-search-93', serviceType:'', forWhom: ''},
    { id: 94, title: 'курсы Медицинский массаж  ', price: '1p.', duration: '', imgClass: 'card__img-search-94', serviceType:'', forWhom: ''},
    { id: 95, title: 'курсы Лечебного массажа', price: '1p.', duration: '', imgClass: 'card__img-search-95', serviceType:'', forWhom: ''},
    { id: 96, title: 'курсы оздоровительного массажа', price: '1p.', duration: '', imgClass: 'card__img-search-96', serviceType:'', forWhom: ''},
    { id: 97, title: 'курсы Детский массаж ', price: '1p.', duration: '', imgClass: 'card__img-search-97', serviceType:'', forWhom: ''},
    { id: 98, title: 'курсы Тайский массаж ', price: '1p.', duration: '', imgClass: 'card__img-search-98', serviceType:'', forWhom: ''},
    { id: 99, title: 'курсы массажа бамбуковыми палочками ', price: '1p.', duration: '', imgClass: 'card__img-search-99', serviceType:'', forWhom: ''},
    { id: 100, title: 'курсы Буккальный массаж ', price: '1p.', duration: '', imgClass: 'card__img-search-100', serviceType:'', forWhom: ''},
    { id: 101, title: 'курсы Вакуумный массаж ', price: '1p.', duration: '', imgClass: 'card__img-search-101', serviceType:'', forWhom: ''},
    { id: 102, title: 'курсы LPG массаж', price: '1p.', duration: '', imgClass: 'card__img-search-102', serviceType:'', forWhom: ''},
    { id: 103, title: 'курсы вакуумно-роликовый массаж ', price: '1p.', duration: '', imgClass: 'card__img-search-103', serviceType:'', forWhom: ''},
    { id: 104, title: 'курсы массажа с сертификатом', price: '1p.', duration: '', imgClass: 'card__img-search-104', serviceType:'', forWhom: ''},
    { id: 104, title: 'курсы массажа с дипломом', price: '1p.', duration: '', imgClass: 'card__img-search-104', serviceType:'', forWhom: ''},
    { id: 105, title: 'курсы аппаратного массажа', price: '1p.', duration: '', imgClass: 'card__img-search-105', serviceType:'', forWhom: ''},
    { id: 106, title: 'курсы эротического массажа', price: '1p.', duration: '', imgClass: 'card__img-search-106', serviceType:'', forWhom: ''},
    { id: 107, title: 'курсы массажа обучение без медицинского образования', price: '1p.', duration: '', imgClass: 'card__img-search-107', serviceType:'', forWhom: ''},
    { id: 108, title: 'курсы массажа с медицинским образованием', price: '1p.', duration: '', imgClass: 'card__img-search-108', serviceType:'', forWhom: ''},
    { id: 109, title: 'обучение массажу +с дипломом', price: '1p.', duration: '', imgClass: 'card__img-search-109', serviceType:'', forWhom: ''},
    { id: 110, title: 'массаж мед обучение', price: '1p.', duration: '', imgClass: 'card__img-search-110', serviceType:'', forWhom: ''},
    { id: 111, title: 'курсы массажа лица обучение', price: '1p.', duration: '', imgClass: 'card__img-search-111', serviceType:'', forWhom: ''},
    { id: 112, title: 'висцеральный массаж обучение', price: '1p.', duration: '', imgClass: 'card__img-search-112', serviceType:'', forWhom: ''},
    { id: 113, title: 'буккальный массаж обучение', price: '1p.', duration: '', imgClass: 'card__img-search-113', serviceType:'', forWhom: ''},
    { id: 114, title: 'курс массажа обучение с медицинским образованием', price: '1p.', duration: '', imgClass: 'card__img-search-114', serviceType:'', forWhom: ''},
    { id: 115, title: 'миофасциальный массаж обучение ', price: '1p.', duration: '', imgClass: 'card__img-search-115', serviceType:'', forWhom: ''},
    { id: 116, title: 'массаж обучение для начинающих', price: '1p.', duration: '', imgClass: 'card__img-search-116', serviceType:'', forWhom: ''},
    { id: 117, title: 'массаж лица обучение', price: '1p.', duration: '', imgClass: 'card__img-search-117', serviceType:'', forWhom: ''},
    { id: 118, title: 'медицинский массаж обучение', price: '1p.', duration: '', imgClass: 'card__img-search-118', serviceType:'', forWhom: ''},
    { id: 119, title: 'массаж тела обучение', price: '1p.', duration: '', imgClass: 'card__img-search-119', serviceType:'', forWhom: ''},
    { id: 120, title: 'массаж стоп обучение', price: '1p.', duration: '', imgClass: 'card__img-search-120', serviceType:'', forWhom: ''},
    { id: 121, title: 'курсы косметологии', price: '1p.', duration: '', imgClass: 'card__img-search-121', serviceType:'', forWhom: ''},
    { id: 122, title: 'курсы косметолога', price: '1p.', duration: '', imgClass: 'card__img-search-122', serviceType:'', forWhom: ''},
    { id: 123, title: 'курсы косметолога эстетиста', price: '1p.', duration: '', imgClass: 'card__img-search-123', serviceType:'', forWhom: ''},
    { id: 124, title: 'курсы косметика эстетиста', price: '1p.', duration: '', imgClass: 'card__img-search-124', serviceType:'', forWhom: ''},
    { id: 125, title: 'курсы депиляции ', price: '1p.', duration: '', imgClass: 'card__img-search-125', serviceType:'', forWhom: ''},
    { id: 126, title: 'курсы эпиляции', price: '1p.', duration: '', imgClass: 'card__img-search-126', serviceType:'', forWhom: ''},
    { id: 127, title: 'курсы Мезотерапии ', price: '1p.', duration: '', imgClass: 'card__img-search-127', serviceType:'', forWhom: ''},
    { id: 128, title: 'курсы Инъекционные методы ', price: '1p.', duration: '', imgClass: 'card__img-search-128', serviceType:'', forWhom: ''},
    { id: 129, title: 'курсы пластический массаж лица ', price: '1p.', duration: '', imgClass: 'card__img-search-129', serviceType:'', forWhom: ''},
    { id: 130, title: 'курсы Сестринское дело в Косметологии  ', price: '1p.', duration: '', imgClass: 'card__img-search-130', serviceType:'', forWhom: ''},
    { id: 131, title: 'курсы перманентный макияж ', price: '1p.', duration: '', imgClass: 'card__img-search-131', serviceType:'', forWhom: ''},
    { id: 132, title: 'Курсы татуаж ', price: '1p.', duration: '', imgClass: 'card__img-search-132', serviceType:'', forWhom: ''},
    { id: 133, title: 'Курсы татуировки', price: '1p.', duration: '', imgClass: 'card__img-search-133', serviceType:'', forWhom: ''},
    { id: 134, title: 'курсы косметологии без медицинского образования', price: '1p.', duration: '', imgClass: 'card__img-search-134', serviceType:'', forWhom: ''},
    { id: 135, title: 'обучающие курсы косметологии', price: '1p.', duration: '', imgClass: 'card__img-search-135', serviceType:'', forWhom: ''},
    { id: 136, title: 'курсы медицинской сестры', price: '1p.', duration: '', imgClass: 'card__img-search-136', serviceType:'', forWhom: ''},
    { id: 137, title: 'курсы инъекционной косметологии ', price: '1p.', duration: '', imgClass: 'card__img-search-137', serviceType:'', forWhom: ''},
    { id: 138, title: 'курсы с медицинским образованием ', price: '1p.', duration: '', imgClass: 'card__img-search-138', serviceType:'', forWhom: ''},
    { id: 139, title: 'курсы аппаратная косметология', price: '1p.', duration: '', imgClass: 'card__img-search-139', serviceType:'', forWhom: ''},
    { id: 140, title: 'курсы химический пилинг', price: '1p.', duration: '', imgClass: 'card__img-search-140', serviceType:'', forWhom: ''},
    { id: 141, title: 'курсы шугаринг', price: '1p.', duration: '', imgClass: 'card__img-search-141', serviceType:'', forWhom: ''},
    { id: 142, title: 'курсы косметика эстетист по лицу', price: '1p.', duration: '', imgClass: 'card__img-search-142', serviceType:'', forWhom: ''},
    { id: 143, title: 'курсы косметика эстетист по телу', price: '1p.', duration: '', imgClass: 'card__img-search-143', serviceType:'', forWhom: ''},
    { id: 144, title: 'курсы пирсинга', price: '1p.', duration: '', imgClass: 'card__img-search-144', serviceType:'', forWhom: ''},
    { id: 145, title: 'курс Контурная пластика', price: '1p.', duration: '', imgClass: 'card__img-search-145', serviceType:'', forWhom: ''},
    { id: 146, title: 'курс Ботулотоксины ', price: '1p.', duration: '', imgClass: 'card__img-search-146', serviceType:'', forWhom: ''},
    { id: 147, title: 'Косметолог эстетист обучение ', price: '1p.', duration: '', imgClass: 'card__img-search-147', serviceType:'', forWhom: ''},
    { id: 148, title: 'Обучение на косметолога', price: '1p.', duration: '', imgClass: 'card__img-search-148', serviceType:'', forWhom: ''},
    { id: 149, title: 'косметолог обучение ', price: '1p.', duration: '', imgClass: 'card__img-search-149', serviceType:'', forWhom: ''},
    { id: 150, title: 'пирсинг', price: '1p.', duration: '', imgClass: 'card__img-search-150', serviceType:'', forWhom: ''},
    { id: 151, title: 'Татуировка', price: '1p.', duration: '', imgClass: 'card__img-search-151', serviceType:'', forWhom: ''},
    { id: 152, title: 'Перманентный макияж ', price: '1p.', duration: '', imgClass: 'card__img-search-152', serviceType:'', forWhom: ''},
    { id: 153, title: 'Маски для лица', price: '1p.', duration: '', imgClass: 'card__img-search-153', serviceType:'', forWhom: ''},


    // Добавьте другие услуги здесь...
  ];
  const [modalVisible, setModalVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleAddToCart = (product) => {
    console.log('Product added to cart:', product);
    setCartItems([...cartItems, product]);
  };



  const handleRemoveFromCart = (index) => {
    const newCartItems = cartItems.filter((item, i) => i !== index);
    setCartItems(newCartItems);
  };

  const [notification, setNotification] = useState({ show: false, message: '' });
  const handleAddToCartWithNotification = (product) => {
    handleAddToCart(product);
    setNotification({ show: true, message: `${product.title} добавлен в корзину` });
    setTimeout(() => {
      setNotification({ show: false, message: '' });
    }, 3000); // Hide notification after 3 seconds
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm('your_service_id', 'your_template_id', e.target, 'your_user_id')
        .then((result) => {
          console.log(result.text);
          setModalVisible(false);
          alert('Ваш номер отправлен. Мы скоро перезвоним!');
        }, (error) => {
          console.log(error.text);
          alert('Произошла ошибка при отправке. Попробуйте еще раз.');
        });
  };


  return (
      <Router>
        <div className='div-tel' onClick={() => setModalVisible(true)}>        <a className='telpage'></a></div>
        {notification.show && (
            <div className="notification">
              {notification.message}
            </div>
        )}

        {modalVisible && (
            <div className="modal">
              <div className="modal-content">
                <h2>Перезвоним за 28 секунд. Оставьте свой номер, и мы оперативно свяжемся с вами!</h2>
                <form onSubmit={handleSubmit}>
                  <input
                      type="tel"
                      name="phone_number"
                      placeholder="Введите ваш номер телефона (+7)"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      required
                  />
                  <button type="submit">Отправить</button>
                  <button type="button" onClick={() => setModalVisible(false)}>Отмена</button>
                </form>
              </div>
            </div>
        )}
        <Header products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart} handleAddToCartWithNotification={handleAddToCartWithNotification} />
        <Breadcrumbs />
        <div className="App">
          <Routes>
            <Route path="/search" element={<SearchPage />} />
            <Route path="/" element={<HomePage products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart} titlee="Волгограде" title="Волгоград" handleAddToCartWithNotification={handleAddToCartWithNotification}/>} />
            <Route path='/Home' element={<HomePage products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart}  handleAddToCartWithNotification={handleAddToCartWithNotification}/>} />
            <Route path='/massage' element={<Massage products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart}  titlee="Волгограде" title="Волгоград" handleAddToCartWithNotification={handleAddToCartWithNotification}/>} />
            <Route path='/massageTwo' element={<MassageTwo products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart}  titlee="Волгограде" title="Волгоград" handleAddToCartWithNotification={handleAddToCartWithNotification}/>} />
            <Route path='/massageface' element={<Massageface products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart}  titlee="Волгограде" title="Волгоград" handleAddToCartWithNotification={handleAddToCartWithNotification}/>} />
            <Route path='/massagechildren' element={<Massagechildren products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart}  titlee="Волгограде" title="Волгоград" handleAddToCartWithNotification={handleAddToCartWithNotification}/>} />
            <Route path='/massageanti' element={<Massageanti products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart} titlee="Волгограде" title="Волгоград" handleAddToCartWithNotification={handleAddToCartWithNotification}/>} />
            <Route path='/massagelpg' element={<Massagelpg products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart}  titlee="Волгограде" title="Волгоград" handleAddToCartWithNotification={handleAddToCartWithNotification}/>} />
            <Route path='/tatu' element={<Tatu products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart}  titlee="Волгограде" title="Волгоград" handleAddToCartWithNotification={handleAddToCartWithNotification}/>} />
            <Route path='/podarok' element={<Podarok products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart}  titlee="Волгограде" title="Волгоград" handleAddToCartWithNotification={handleAddToCartWithNotification}/>} />
            <Route path='/cosmet' element={<Cosmet products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart}  titlee="Волгограде" title="Волгоград" handleAddToCartWithNotification={handleAddToCartWithNotification}/>} />
            <Route path='/obuc' element={<Obuc products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart}  titlee="Волгограде" title="Волгоград" handleAddToCartWithNotification={handleAddToCartWithNotification}/>} />
            <Route path='/abon' element={<Abon products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart}  titlee="Волгограде" title="Волгоград" handleAddToCartWithNotification={handleAddToCartWithNotification}/>} />
            <Route path='/obuct' element={<Obuct products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart}  titlee="Волгограде" title="Волгоград" handleAddToCartWithNotification={handleAddToCartWithNotification}/>} />
            <Route path='/obucc' element={<Obucc products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart}  titlee="Волгограде" title="Волгоград" handleAddToCartWithNotification={handleAddToCartWithNotification}/>} />
            <Route path="/cart" element={<Cart  products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart}/>} handleAddToCartWithNotification={handleAddToCartWithNotification}/>
            <Route path="/polyt" element={<Polyt products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart}/>} handleAddToCartWithNotification={handleAddToCartWithNotification}/>
            <Route path='/spatwo' element={<SpaTwo  products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart} titlee="Волгограде" title="Волгоград" handleAddToCartWithNotification={handleAddToCartWithNotification}/>} />
            <Route path='/spa' element={<Spa products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart} titlee="Волгограде" title="Волгоград" handleAddToCartWithNotification={handleAddToCartWithNotification}/>} />
          </Routes>
        </div>
        <Footer />
      </Router>
  );
}

export default App;
