import React from 'react';
import Slider from 'react-slick';
import './massage.css'


const products = [
    { id: 806, title: "Массаж ребёнку", price: 500,spis1: 'Лечебный детский массаж от 1 месяца  ',duration:'30 мин.', spis2:'Профилактический детский массаж от 1 месяца', desc: '', city: '' , imgClass: 'card__img-3 card__img-search-201'},
];









const reviews = [
    {
        name: "Ксения",
        date: "17.05.2023",
        rating: "отлично",
        advantages: "Расслабляет",
        disadvantages: "Хочется еще",
        comment: "Решила первый раз пойти на масляный массаж. Зря не попробовала это чудо раньше...",
        adminResponse: "Спасибо за Ваш отзыв"
    },
    {
        name: "Ксения2",
        date: "17.05.2023",
        rating: "отлично",
        advantages: "Расслабляет",
        disadvantages: "Хочется еще",
        comment: "Решила первый раз пойти на масляный массаж. Зря не попробовала это чудо раньше...",
        adminResponse: "Спасибо за Ваш отзыв"
    },
    // Добавьте другие отзывы сюда
];

const NextArrow = (props) => {
    const { onClick } = props;
    return (
        <div className="slick-arrow slick-next" onClick={onClick}>
            ›
        </div>
    );
};

const PrevArrow = (props) => {
    const { onClick } = props;
    return (
        <div className="slick-arrow slick-prev" onClick={onClick}>
            ‹
        </div>
    );
};
const massagechildren = ({title, titlee, handleAddToCart, cartItems, handleRemoveFromCartне}) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };
    return (
        <div className="massage-page">
            <main className="main-block">
                <h1>Детский массаж в {titlee}</h1>

                <h2>
                    Детский массаж - максимально простая и одновременно полезная манипуляция, помогающая малышу развиваться на каждом этапе своей жизни, что оставляет след на всю дальнейшую его жизнь. Также, в детском возрасте - это лечебно-профилактическое средство от многих заболеваний, с которыми может столкнуться малыш. Если говорить о детях раннего возраста, то медицинский массаж - это лучшее средство, помогающее правильно формировать все умения маленького человечка. <br />
                    Детский массаж оказывает позитивное влияние не только на физическую форму и состояние костно-мышечного скелета, но и на работу внутренних органов и всех систем организма. Во время выполнения мануальных воздействий активируются все резервные возможности малышового организма. Если речь идет о детях первого года жизни, то это лучший метод, позволяющий максимально безобидно перенести послеродовой стресс и адаптироваться к новым условиям жизни.<br />
                    Детский организм отличается высокой чувствительностью к стимуляции через кожные покровы, а поэтому массаж для детей - это эффективный комплекс, который дает поразительные результаты и подходит для малышей разного возраста. Выполнять эти техники необходимо грамотно только опытными массажистами.Детский массаж можно условно разделить с учетом целей и возрастной особенности Чадо.<br />
                    Пассивная лечебная гимнастика в виде массажа у детей первого года жизни. Такой мануальный метод поможет адаптироваться малышу к этому незнакомому для него окружающему миру, укрепить костно-мышечную систему, повысить тонус, эластичность, а также улучшить обменные процессы в организме и состояние кровеносной системы. Эффективность таких сеансов в практическом проявлении: кроха научится управлять своими ручками, ножками, удерживать головку, а также переворачиваться, сидеть, стоять и даже ходить.У детей с года все движения более активные, можно использовать точечную технологию, что улучшает метаболизм и укрепляет иммунную систему. Детский массаж профилактический не имеет практически противопоказаний. В нашем салоне красоты и здоровья сертифицированный массажист контролирует всю технику выполнения, чтобы не оказать вред здоровому ребенку.<br />
                    Детский массаж лечебный - одна из разновидностей мануальной техники и показан крохам с ослаблением опорно-двигательного каркаса, а также патологиями со стороны нервной системы. Такие методики помогают не только ребенку избавиться от существующих проблем или улучшить качество жизни, но и снизить болевой синдром, достичь психического равновесия. Детский массаж - это оздоравливающий комплекс, с помощью которого вы сможете подарить малышу радость и ласку!
                </h2>
            </main>

            <section className="massage-second-block">
                <h1>Наши услуги</h1>
                <div className="cards-m">

                    {products.map((product) => (
                        <div key={product.id} className="massage-card">
                            <div className={product.imgClass}></div>
                            <div style={{flex: 0.5}}>
                                <h1 className="card__title">{product.title}</h1>
                                <ul className="mas-u">
                                    {product.spis1 && <li>{product.spis1}</li>}
                                    {product.spis2 && <li>{product.spis2}</li>}
                                    {product.spis3 && <li>{product.spis3}</li>}
                                    {product.spis4 && <li>{product.spis4}</li>}
                                    {product.spis5 && <li>{product.spis5}</li>}
                                    {product.spis6 && <li>{product.spis6}</li>}
                                    {product.spis7 && <li>{product.spis7}</li>}
                                    {product.spis8 && <li>{product.spis8}</li>}
                                    {product.spis9 && <li>{product.spis9}</li>}
                                    {product.spis10 && <li>{product.spis10}</li>}
                                    {product.spis11 && <li>{product.spis11}</li>}
                                    {product.spis12 && <li>{product.spis12}</li>}
                                    {product.spis13 && <li>{product.spis13}</li>}
                                    {product.spis14 && <li>{product.spis14}</li>}
                                </ul>
                                <button className="card__btn" onClick={() => handleAddToCart(product)}>Забронировать</button>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            <main className="middle-block">
                <div className="title-block-mas">
                    <h1>3 причины выбрать нас</h1>
                    <h2>Мы работаем каждый день, чтобы сделать вашу жизнь счастливее</h2>
                </div>
            <div className="hg">
                <div className="sv">
                <svg class="svgg"  fill="#69b974" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <path d="M50.1 98.9c-26.7 0-48.5-21.8-48.5-48.5S23.4 1.9 50.1 1.9c8.9 0 17.5 2.4 25.1 7 .7.4.9 1.4.5 2.1-.4.7-1.4.9-2.1.5C66.5 7.2 58.4 5 50.1 5 25 4.9 4.6 25.3 4.6 50.4S25 95.9 50.1 95.9s45.5-20.4 45.5-45.5c0-3.2-.3-6.4-1-9.5-.2-.8.3-1.6 1.2-1.8.8-.2 1.6.3 1.8 1.2.7 3.3 1.1 6.7 1.1 10.1-.1 26.7-21.8 48.5-48.6 48.5z">
                <path d="M50.1 63.9c-.4 0-.8-.2-1.1-.4L24.8 39.2c-.6-.6-.6-1.5 0-2.1.6-.6 1.5-.6 2.1 0l23.2 23.2 46.8-48c.6-.6 1.5-.6 2.1 0 .6.6.6 1.5 0 2.1l-47.8 49c-.3.3-.7.5-1.1.5z">
                </path></path>
                </svg>
                </div>

                <div>
                    <h5>Высокое качество</h5>
                    <h6>Наши специалисты постоянно повышают свою квалификацию и участвуют в международных конференциях.</h6>
                </div>
            </div>
            <div className="hg">
            <div className="sv">
                <svg class="svgg"  fill="#69b974" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <path d="M50.1 98.9c-26.7 0-48.5-21.8-48.5-48.5S23.4 1.9 50.1 1.9c8.9 0 17.5 2.4 25.1 7 .7.4.9 1.4.5 2.1-.4.7-1.4.9-2.1.5C66.5 7.2 58.4 5 50.1 5 25 4.9 4.6 25.3 4.6 50.4S25 95.9 50.1 95.9s45.5-20.4 45.5-45.5c0-3.2-.3-6.4-1-9.5-.2-.8.3-1.6 1.2-1.8.8-.2 1.6.3 1.8 1.2.7 3.3 1.1 6.7 1.1 10.1-.1 26.7-21.8 48.5-48.6 48.5z">
                <path d="M50.1 63.9c-.4 0-.8-.2-1.1-.4L24.8 39.2c-.6-.6-.6-1.5 0-2.1.6-.6 1.5-.6 2.1 0l23.2 23.2 46.8-48c.6-.6 1.5-.6 2.1 0 .6.6.6 1.5 0 2.1l-47.8 49c-.3.3-.7.5-1.1.5z">
                </path></path>
                </svg>
                </div>
                <div>
                    <h5>Чистота и комфорт</h5>
                    <h6>Для нас важно, чтобы клиенты чувствовали себя комфортно. Вот почему мы всегда следим за чистотой и атмосферой наших салонов.</h6>
                </div>
            </div>
            <div className="hg">
            <div className="sv">
                <svg class="svgg"  fill="#69b974" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <path d="M50.1 98.9c-26.7 0-48.5-21.8-48.5-48.5S23.4 1.9 50.1 1.9c8.9 0 17.5 2.4 25.1 7 .7.4.9 1.4.5 2.1-.4.7-1.4.9-2.1.5C66.5 7.2 58.4 5 50.1 5 25 4.9 4.6 25.3 4.6 50.4S25 95.9 50.1 95.9s45.5-20.4 45.5-45.5c0-3.2-.3-6.4-1-9.5-.2-.8.3-1.6 1.2-1.8.8-.2 1.6.3 1.8 1.2.7 3.3 1.1 6.7 1.1 10.1-.1 26.7-21.8 48.5-48.6 48.5z">
                <path d="M50.1 63.9c-.4 0-.8-.2-1.1-.4L24.8 39.2c-.6-.6-.6-1.5 0-2.1.6-.6 1.5-.6 2.1 0l23.2 23.2 46.8-48c.6-.6 1.5-.6 2.1 0 .6.6.6 1.5 0 2.1l-47.8 49c-.3.3-.7.5-1.1.5z">
                </path></path>
                </svg>
                </div>
                <div>
                    <h5>Индивидуальный подход</h5>
                    <h6>Наша компания работает по принципу индивидуального подхода к каждому клиенту. Мы уважаем ваши пожелания и предпочтения.</h6>
                </div>
            </div>
            </main>

            <div className="review-slider">
            <h1>Отзывы об услуге</h1>
            <Slider {...settings}>
                {reviews.map((review, index) => (
                    <div key={index} className="review">
                        <p><strong>{review.name} {review.date}</strong></p>
                        <p>{review.rating}</p>
                        <p><strong>Достоинства:</strong> {review.advantages}</p>
                        <p><strong>Недостатки:</strong> {review.disadvantages}</p>
                        <p><strong>Комментарий:</strong> {review.comment}</p>
                        <p><strong>Ответ администратора {review.date}</strong></p>
                        <p>{review.adminResponse}</p>
                    </div>
                ))}
            </Slider>
            </div>

            <section className='fo-block'>
                <h1>Специалисты</h1>
                <h2>Опытные профессионалы своего дела!</h2>

                <div className="spec">
                    <div className="spec-card">
                        <div className="spec-img-1"></div>

                        <div className="spec-title">
                        Массаж— поистине это полезно и предотвращает от болезней органов 
                        </div>
                    </div>

                    <div className="spec-card">
                        <div className="spec-img-2"></div>

                        <div className="spec-title">
                        Эффект массажа — естественная восстанавливающая сила организма, сила жизни 
                        </div>
                    </div>

                    <div className="spec-card">
                        <div className="spec-img-3"></div>

                        <div className="spec-title">
                        Массаж во врачевании во все времена играл огромную роль, и люди давно должны были поставить памятник рукам массажиста.
                        </div>
                    </div>

                    <div className="spec-card">
                        <div className="spec-img-4"></div>

                        <div className="spec-title">
                        Наше тело всё время говорит с нами. Если бы мы только нашли время послушать
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default massagechildren;