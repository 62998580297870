import React from 'react';
import './massage.css'
const products = [
    { id: 189, title: "Спа процедуры и массаж для двоих в spa салоне Волгограда", spis1:'пилинг-массаж перчаткой kessa-30минут',spis2:'relax-массаж с арома-релаксацией-1 час',spis3:'relax-массаж лица-30минут',spis4:'Комплимент для гостей.', rice: 10000, desc: 'Время — 2 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-189'},
    { id: 190, title: "Японская СПА программа \"Сакура\"",spis1:'Согревающий массаж — 30 минут',spis2:'Японский массаж с aroma релаксацией— 1 час',spis3:'Комплимент для гостей.', price: 8000, desc: 'Время — 1.30 минут', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-190'},
    { id: 191, title: "Тибетская СПА программа \"Вершина мира\"- 1.30 часа\n",spis1:'согревающий массаж - 30 минут',spis2:'Тибетский массаж с поющими чашами и aroma релаксацией— 1 час',spis3:'Компплимент для гостей.', price: 8000, desc: 'Время — 1.30 минут', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-191'},
    { id: 192, title: "Марокканская Спа программа-Радость Марракеша",spis1:'Согревающий массаж - 30 минут',spis2:'Марокканский массаж c аргановым маслом— 1 час',spis3:'Комплимент для гостей.', price: 8000, desc: 'Время — 1.30 минут', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-192'},
    { id: 193, title: "Креольская СПА программа \"Сейшилы Spa\"",spis1:'согревающий массаж - 30 минут',spis2:'Креольский массаж бамбуковыми палочками c aroma-relax— 1 час',spis3:'Комплимент для гостей.', price: 8000, desc: 'Время — 1.30 минут', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-193'},
    { id: 194, title: "Индийская СПА программа \"На Гоа\"", spis1:'пилинг-массаж - 30 минут',spis2:'Индийский массаж на горячем масле с aroma-релаксацией - 1 час',spis3:'Комплимент для гостей.',price: 8000, desc: 'Время — 1.30 минут', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-194'},
    { id: 195, title: "Испанская СПА программа \"Коррида\"", spis1:'Согревающий массаж — 30 минут',spis2:'Испанский массаж с aroma-релаксацией — 1 час',spis3:'Комплимент для гостей.',price: 8000, desc: 'Время — 1.30 минут', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-195'},
    { id: 196, title: "СПА программа \"Energy\"", spis1:'аккупунктурный массаж стоп - 30 минут',spis2:'спортивный массаж с аромарелаксацией - 1 час',spis3:'Комплимент для гостей',price:  8000, desc: 'Время — 1.30 минут', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-196'},
    { id: 197, title: "СПА программа \"Медитация\"", spis1:'согревающий массаж — 30 минут',spis2:'Relax-массаж и медитация — 1 час',spis3:'Комплимент для гостей',price: 8000, desc: 'Время — 1.30 минут', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-197'},
    { id: 198, title: "СПА программа \"Тайская сказка\"", spis1:'согревающий пилинг-массаж - 30 минут;',spis2:'тайский арома-массаж - 30 минут;',spis3:'тайский массаж рук, стоп и головы - 30 минут;',spis4:'aroma-релаксация всего тела - 30 минут;',spis5:'Комплимент для гостей',price: 10000, desc: 'Время — 2 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-198'},
    { id: 199, title: "СПА программа \"Тропическая ночь\"",spis1:'массаж горячими камнями с расстановкой по точкам-30минут',spis2:'Relax-массаж на нежном кокосовом молочке-30минут',spis3:'aroma-релаксация-30 минут',spis4:'Комплимент для гостей.', price: 8000, desc: 'Время — 1.30 минут', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-199'},
    { id: 200, title: "СПА программа \"Жизнь в шоколаде\"", spis1:'согревающий стоун-массаж горячими камнями - 30 минут;',spis2:'точечный массаж стоп и рук - 30 минут;',spis3:'пилинг-массаж перчаткой Kessa - 30 минут;',spis4:'обертывание с воздушным шоколадом - 30 минут',spis5:'relax-массаж лица на воздушном шоколаде(во время обертывания)',spis6:'Комплимент для гостей',price: 10000, desc: 'Время — 2 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-200'},
    { id: 201, title: "СПА программа \"Монарх\"",spis1:'согревающий пилинг-массаж - 1 час',spis2:'Аюрведческий массаж стоп - 30 минут',spis3:'королевский массаж с Vip-маслами на выбор - 30минут',spis4:'Комплимент для гостей.', price: 15000, desc: 'Время — 2 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-201'},
    { id: 202, title: "СПА программа \"Новогодний глинтвейн\"", spis1:'согревающий массаж - 1 час;',spis2:'Обновляющий пилинг-массаж - 30 минут;',spis3:'Массаж «Жато Велнесс» с горячим Глинтвейном (антистрессовый,глубокая проработка мышц) - 30 минут;',spis4:'Новогодний комплимент для гостей.',price: 10000, desc: 'Время — 2 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-202'},
    { id: 203, title: "СПА программа \"Rehabilitation\"", spis1:'согревающий пилинг-массаж — 30 минут',spis2:'массаж Жато Велнесс с aroma релаксацией — 1 час',spis3:'Комплимент для гостей.',price: 8000, desc: 'Время — 1.30 минут', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-203'},
    { id: 204, title: "СПА программа \"Совершенство\"", spis1:'согревающий массаж - 30 минут;',spis2:'отчищающий пилинг-массаж - 30 минут;',spis3:'Моделирующий массаж - 30 минут;',spis4:'Обёртывание по показаниям: Крио-активное,термо-активное, дренажное - 30 минут;',spis5:'Комплимент для гостей',price: 10000, desc: 'Время — 2 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-204'},
    { id: 205, title: "СПА программа \"Второе дыхание\"", spis1:'согревающий массаж - 1 час;',spis2:'Тибетский массаж - 30 минут;',spis3:'Антиоксидантное обертывание с вытяжкой из водорослей и зеленого чая - 30 минут;',spis4:'Комплимент для гостей.',price: 10000, desc: 'Время — 2 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-205'},
    { id: 206, title: "СПА программа \"Под пальмой\"", spis1:'массаж бамбуковыми палочками и горячими камнями — 30 минут',spis2:'массаж тела на кокосовом молочке с aroma релаксацией— 1 час',spis3:'Комплимент для гостей.',price:  8000, desc: 'Время — 1.30 минут', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-206'},
    { id: 207, title: "СПА программа для пары \"Рай на двоих\"",spis1:'для двоих - Согревающий пилинг-массаж - 30 минут',spis2:'для нее - Тайский маслянный aroma-массаж горячим маслом кокоса - 1 час',spis3:'Для него - Тайский-Йога массаж и aroma-релаксация - 1 час',spis4:'для двоих - Тайский массаж лица и головы - 30 минут',spis5:'Комплимент для пары', price: 10000, desc: 'Время — 2 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-207'},
    { id: 208, title: "СПА программа для пары \"Сладкая парочка\"", spis1:'пилинг-массаж - 30минут',spis2:'для него - массаж с элементами Тайского на кокосовом масле c aroma релаксацией - 1 час',spis3:'для нее - расслабляющий массаж на шоколаде с мятой или нежным фруктовым муссом с aroma релаксацией - 1 час',spis4:'для него - точечный массаж стоп и рук - 30минут',spis5:'для нее - комплексный уход за лицом: расслабляющий массаж лица, увлажняющая крем-маска - 30минут',spis6:'Комплимент для пары.',price: 10000, desc: 'Время — 2 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-208'},
    { id: 209, title: "СПА программа \"Приключения в Тае\"",spis1:'для двоих - согревающий пилинг-массаж - 1 час',spis2:'для двоих - Relax-массаж стоп с кокосовым молочком - 30 минут',spis3:'для него — Тайский массаж лица и головы - 30 минут',spis4:'для неё — Тайский маслянный aroma-массаж на горячем масле кокоса - 30 минут',spis5:'Комплимент для двоих.', price: 10000, desc: 'Время — 2 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-209'},
    { id: 210, title: "СПА программа \"Летнее настроение\"", spis1:'согревающий пилинг массаж - 1 час',spis2:'relax-массаж с экзотическими фруктами - 30 минут',spis3:'Комплимент для гостей.',price: 8000, desc: 'Время — 1.30 минут', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-210'},
    { id: 211, title: "СПА программа \"Кокосовый рай\"", spis1:'согревающий пилинг массаж — 1 час;',spis2:'Тайский маслянный массаж на горячем масле на выбор — 1 час;',spis3:'Комплимент для гостей',price: 10000, desc: 'Время — 2 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-211'},
    { id: 212, title: "СПА программа \"Путешествие на Гоа\"", spis1:'согревающий массаж - 1 час;',spis2:'Индийский маслянный массаж с aroma релаксацией — 1 час;',spis3:'Комплимент для гостей.',price: 10000, desc: 'Время — 2 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-212'},
    { id: 213, title: "СПА программа \"Relax\"", spis1:'Согревающий массаж — 30 минут',spis2:'аккупунктурный массаж с aroma релаксацией - 1 час',spis3:'Комплимент для гостей',price: 8000, desc: 'Время — 1.30 минут', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-213'},
    { id: 214, title: "СПА программа \"Slimming day\"", spis1:'стоун-массаж горячими камнями - 30 минут;',spis2:'отчищающий пилинг-массаж — 30 минут;',spis3:'антицеллюлитный Slim-массаж проблемных зон с контрастным обёртыванием — 1 час;',spis4:'Комплимент для гостей',price: 10000, desc: 'Время — 2 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-214'},
    { id: 215, title: "СПА программа \"Цитрусовый взрыв\"",spis1:'согревающий пилинг-массаж - 1 час;',spis2:'Моделирующий массаж на цитрусовых маслах на выбор — 1 час;',spis3:'Комплимент для гостей.', price: 10000, desc: 'Время — 2 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-215'},
    { id: 216, title: "СПА программа \"Путешествие на Тибет\"", spis1:'согревающий пилинг-массаж - 1 час;',spis2:'Тибетский маслянный массаж с горячим маслом на выбор — 1 час;',spis3:'Комплимент для гостей.',price: 10000, desc: 'Время — 2 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-216'},
    { id: 217, title: "Vip СПА программа \"Полёт на Мальдивы\"",spis1:'согревающий пилинг-массаж — 1 час;',spis2:'Мальдивский массаж на горячем масле «Полинезия» — 1 час;',spis3:'Комплимент для гостей;', price: 10000, desc: 'Время — 2 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-217'},
    { id: 218, title: "Vip СПА программа \"Коста-Рика\"", spis1:'Согревающий массаж — 1 час;',spis2:'отчищающий пилинг-массаж — 30 минут;',spis3:'Креольский массаж с горячим маслом миндаля — 1 час;',spis4:'Марроканский массаж лица — 30 минут',spis5:'Комплимент для гостей.',price: 15000, desc: 'Время — 3 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-218'},
    { id: 219, title: "Vip СПА программа \"Атлантида\"", spis1:'согревающий массаж — 1 час;',spis2:'Пилинг-массаж всего тела — 30 минут;',spis3:'Тайский маслянный аромамассаж на горячих Vip-маслах - 1 час;',spis4:'Испанский массаж лица — 30 минут;',spis5:'Комплимент для гостей',price: 15000, desc: 'Время — 3 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-219'},
    { id: 220, title: "Vip СПА программа \"Сибирь\"", spis1:'согревающий пилинг-массаж - 30 минут',spis2:'Стоун-массаж горячими камнями — 30 минут.',spis3:'Русский массаж с Сибирскими ягодами — 1.30 часа;',spis4:'Массаж вениками - 30 минут',spis5:'Комплимент для гостей;',price: 15000, desc: 'Время — 3 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-220'},
    { id: 221, title: "Vip СПА программа \"Семь чудес света\"", spis1:'',spis2:'',spis3:'',price: 2500, desc: '', city: '' , imgClass: 'card__img-3 card__img-search-221'},
    { id: 222, title: "Vip СПА программа \"Жемчужина моря\"", spis1:'согревающий массаж — 1 час;',spis2:'очищающий пилинг массаж рукавичками Kessa — 30 минут;',spis3:'тайский или индийский массаж на выбор и обёртывание с экстрактом жемчуга и лепестками розы — 60 минут;', spis4:'массаж лица с экстрактом жемчуга и лепестками розы — 30 минут;',spis5:'комплимент для гостей',price: 15000, desc: 'Время — 3 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-222'},
    { id: 223, title: "Vip СПА программа \"Роскошь Океана\"",spis1:'согревающий пилинг-массаж — 1 час;',spis2:'стоун-массаж горячими камнями — 30 минут;',spis3:'relax массаж и питание тела протеином черной икры — 1 час;',spis4:'массаж лица воздушным кремом с протеином черной икры — 30 минут;', price: 15000, desc: 'Время — 3 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-223'},
]
const SpaTwo = ({title, titlee, handleAddToCart, cartItems, handleRemoveFromCart, handleAddToCartWithNotification}) => {
    return (
        <div className="massage-page">
            <main className="main-block">
                <h1>Спа программы и массаж для двоих</h1>
                <h2>Опытные руки мастеров {title}а ждут Вас.</h2>

                <div className='thr-col'>
                    <div className='fr-col'>
                        <div className='col-icon-1'></div>
                        <h2>Отдых</h2>
                        <p>
                            Выбрать приятный-расслабляющий массаж для двоих или пары в spa салон Волгограда, на яркий подарок впечатление для полноценного отдыха двоим.                        </p>
                    </div>

                    <div className='sc-col'>
                        <div className='col-icon-2'></div>
                        <h2>Доступность</h2>
                        <p>
                            Подробное описание спа-процедур и программ для двоих с возможностью посмотреть цены и сравнить их стоимость, телефоны и адреса в Волгограде.
                        </p>
                    </div>

                    <div className='th-col'>
                        <div className='col-icon-3'></div>
                        <h2>Цена</h2>
                        <p>
                            СПА услуги и массаж для двоих в Волгограде на официальном сайте Академии Спа от 8000₽.
                        </p>
                    </div>
                </div>
            </main>

            <main className="thride-block">
                <h1>Спа процедуры и массаж для двоих в spa салоне {title}а</h1>

                <div className="thride-block__cards">
                    {products.map((product) => (
                        <div key={product.id} className="thride-block__card">
                            <div className={product.imgClass} style={{flex:'inherit'}}></div>
                            <h1 className="card__title">{product.title}</h1>
                            <h2 className="card__subtitle">{product.desc}</h2>
                            <h2 className="card__subtitle">{product.price}</h2>
                            <h2 className="card__subtitle">{product.city}</h2>
                            <button className="card__btn" onClick={() => handleAddToCartWithNotification(product)}>Добавить в корзину</button>
                        </div>
                    ))}
                </div>
            </main>

        </div>
    );
}

export default SpaTwo;