import React, { useState } from 'react';
import Slider from 'react-slick';
import './massage.css'
const products = [
    { id: 917, title: "Курсы обучения татуировке", price: 'по договоренности', desc: '', city: '' , imgClass: 'card__img-5 obuc-tat917'},
]



const courses = [
    {
      title: 'Курсы тату-базовый(Профи)',
      content: (
        <>
          <p><strong>Программа курса:</strong></p>
          <p><strong>Теоретическая часть:</strong></p>
          <ul>
            <li>Изучение стилей — их специфики, особенностей и отличий;</li>
            <li>Санитарные требования к кабинету, организация рабочего места;</li>
            <li>Оборудование: как грамотно его подобрать и купить по разумной цене;</li>
            <li>Сборка и настройка тату машинок;</li>
            <li>Расходные материалы;</li>
            <li>Противопоказания к татуировке;</li>
            <li>Уход и заживление;</li>
            <li>рекомендации по работе и общению с ребенком от практикующих мастеров;</li>
              <li>разбор вопросов с преподавателем;</li>
              <li>проверка усвоенного материала.</li>

          </ul>
          <p><strong>Практическая часть:</strong></p>
          <ul>
            <li>Контур, тень, грейвош, градиент и особенности их выполнения;</li>
            <li>Обрисовка и перевод эскиза на кожу;</li>
            <li>изучение техник выполнения;</li>
            <li>Отработка практических навыков;</li>
            <li>Экзамен</li>
          </ul>
          <p><strong>Продолжительность:</strong> 35 ак.часа</p>
          <p><strong>Очно в группе 35500₽</strong></p>
          <p><strong>Очно-индивидуально 75500₽</strong></p>
        </>
      )
    },
    { title: 'Курс Мини-тату',
    content: (
        <>
          <p><strong>Программа курса:</strong></p>
          <p><strong>Теоретическая часть:</strong></p>
          <ul>
            <li>Виды оборудования, санитарные требования, стерилизация;</li>
            <li>Организация рабочего места;</li>
            <li>Сборка и настройка тату машинок;</li>
            <li>показания противопоказания к тату;</li>
            <li>Расходные материалы: иглы, носики и тд — как выбрать;</li>
            <li>Техники и стили татуировок;</li>
            <li>требования к тату-мастеру;</li>
            <li>разбор вопросов с преподавателем;</li>
              <li>проверка усвоенного материала.</li>
          </ul>
          <p><strong>Практическая часть:</strong></p>
          <ul>
            <li>Подготовка и перевод эскиза на кожу;</li>
            <li>Правила заживления и ухода за татуировкой;</li>
            <li>изучение техники выполнения;</li>
          </ul>
          <p><strong>Продолжительность:</strong> 16 ак.часа</p>
          <p><strong>Очно в группе 15000₽</strong></p>
          <p><strong>Очно-индивидуально 25000₽</strong></p>
        </>
      ) },
    { title: 'Обучение Перманентному макияжу',
    content: (
        <>
          <p><strong>Программа курса:</strong></p>
          <p><strong>Теоретическая часть:</strong></p>
          <ul>
            <li>История и суть перманентного макияжа;</li>
            <li>показания противопоказания;</li>
            <li>Стерилизация и санитарные нормы;</li>
            <li>Обустройство рабочего места;</li>
            <li>асептика и антисептика;</li>
            <li>Строение и типы кожи;</li>
            <li>Колористика и пигменты;</li>
            <li>Анатомические вопросы (строение черепа, положение мышц, кости);</li>
              <li>Правила и приемы в визаже, эскиз;</li>
              <li>Подготовка кожи к процедуре, регенерация кожи, обработка после процедуры;</li>
              <li>Анестезия виды и противопоказания;</li>
              <li>Ошибки и методы их исправления (разбираем ошибки учеников и других мастеров, учимся их исправлять);</li>
              <li>Брови (виды нанесения перманентного макияжа;</li>
              <li>Глаза. Классическая стрелка, межресничка. Губы. Контур растушевка;</li>
              <li>разбор вопросов с преподавателем;</li>
              <li>проверка усвоенного материала.</li>
          </ul>
          <p><strong>Практическая часть:</strong></p>
          <ul>
            <li>Приёмы нанесения перманентного макияжа;;</li>
            <li>изучение техники выполнения;</li>
            <li>Отработка практических навыков.</li>
          </ul>
          <p><strong>Продолжительность:</strong> 35 ак.часа</p>
          <p><strong>Очно в группе 35500₽</strong></p>
          <p><strong>Очно-индивидуально 75500₽</strong></p>
        </>
      ) },

  ];
  
  const Accordion = ({ title, content }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    return (
      <div className="accordion-item">
        <div className="accordion-title" onClick={() => setIsOpen(!isOpen)}>
          <h3>{title}</h3>
          <span>{isOpen ? '-' : '+'}</span>
        </div>
        {isOpen && (
          <div className="accordion-content">
            <p>{content}</p>
            <a href='' className="zapisatsya">Записаться</a>
          </div>
        )}
      </div>
    );
  };

  const reviews = [
    {
        image: "./svidetelstvo2.jpg"
    },
    {
        image: "./svidetelstvo.jpg" 
        
    },
    {
        image: "./sertifikat0.jpg" 
    },
    // Добавьте другие отзывы сюда
];

const NextArrow = (props) => {
    const { onClick } = props;
    return (
        <div className="slick-arrow slick-next" onClick={onClick}>
            ›
        </div>
    );
};

const PrevArrow = (props) => {
    const { onClick } = props;
    return (
        <div className="slick-arrow slick-prev" onClick={onClick}>
            ‹
        </div>
    );
};
const obuct = ({title, titlee, handleAddToCart, cartItems, handleRemoveFromCart, handleAddToCartWithNotification}) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };
    return (
        <div className="massage-page">
            <main className="main-block">
                <h1>Курсы обучения татуировке</h1>

                <h2>
                Учебный центр Академии тату в Волгограде начал свою работу с 2018 года. В настоящий момент компания работает с тату студиями по всей России, которые проводят индивидуальное обучение и групповые занятия до 10 человек на преподавателя. Доступен большой дисконт для наших учеников.
                <br />

                Ищешь школу или курсы обучения на которых можешь изучить все тонкости нанесения татуировок, освоить разные техники и стили, поработать на современном оборудовании под присмотром опытных мастеров и узнать о том, как работает тату салон изнутри? Добро пожаловать в нашу Академию, мы устроим тебе полное погружение и научим делать татуировки!
                <br />

                В штате нашей компании работает более 50 ведущих тату-мастеров всех стилей художественной татуировки. За время работы мы подготовили более 2000 специалистов. Наши выпускники работают в частной практике и лучших студиях СНГ, Европы и США. Все обучающие центры имеют лицензии образования и соблюдают нормы СанПиНа.
                <br />
                На официальном сайте подобраны короткие и длинные учебные курсы по всем существующим техникам татуировки, которые подойдут как новичкам, так и для повышения квалификации опытного тату-мастера.
                </h2>
            </main>


            <div className="accord-block">
                {courses.map((course, index) => (
                    <Accordion key={index} title={course.title} content={course.content} />
                ))}
            </div>

            {products.map((product) => (
                <div key={product.id} className="btn-block">

                    <button className="btn-block" style={{border: 0, cursor: "pointer"}}  onClick={() => handleAddToCartWithNotification(product)}>Записаться →</button>
                </div>
            ))}

            <div className="review-slider" style={{height: 500}}>
              <h1>Отзывы об услуге</h1>
              <Slider {...settings}>
                  {reviews.map((review, index) => (
                      <div key={index} className="review">
                          <img className='img-sl' src={review.image} alt={`Отзыв ${index + 1}`} /> {/* Добавьте элемент img */}
                      </div>
                  ))}
              </Slider>
          </div>
        </div>
    );
}

export default obuct