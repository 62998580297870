import React from 'react';
import Slider from 'react-slick';
import './massage.css'

const products = [
    { id: 503, title: "Антицеллюлитный массаж", price: 1, desc: '', city: '' , imgClass: 'card__img-3 mas-ant-b'},
];











const reviews = [
    {
        name: "Ксения",
        date: "17.05.2023",
        rating: "отлично",
        advantages: "Расслабляет",
        disadvantages: "Хочется еще",
        comment: "Решила первый раз пойти на масляный массаж. Зря не попробовала это чудо раньше...",
        adminResponse: "Спасибо за Ваш отзыв"
    },
    {
        name: "Ксения2",
        date: "17.05.2023",
        rating: "отлично",
        advantages: "Расслабляет",
        disadvantages: "Хочется еще",
        comment: "Решила первый раз пойти на масляный массаж. Зря не попробовала это чудо раньше...",
        adminResponse: "Спасибо за Ваш отзыв"
    },
    // Добавьте другие отзывы сюда
];

const NextArrow = (props) => {
    const { onClick } = props;
    return (
        <div className="slick-arrow slick-next" onClick={onClick}>
            ›
        </div>
    );
};

const PrevArrow = (props) => {
    const { onClick } = props;
    return (
        <div className="slick-arrow slick-prev" onClick={onClick}>
            ‹
        </div>
    );
};
const massageanti = ({title, titlee, handleAddToCart, cartItems, handleRemoveFromCart, handleAddToCartWithNotification}) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };
    return (
        <div className="massage-page">
            <main className="main-block">
                <h1 >Антицеллюлитный массаж в {titlee}</h1>

                <h2 className='anti'>
                Целлюлит – это не эстетический недостаток, а нарушение в работе организма. Жир не выводится из организма, а накапливается в подкожных клетках. Поверхность кожи становится бугристой и напоминает апельсиновую корку. Нарушается ток крови и лимфы, из-за чего клетки получают меньше питательных веществ, из них не выводятся токсины, появляются отеки. Попытки избавится от целлюлита, уменьшив калорийность рациона и занявшись спортом, не эффективны. Организм сохраняет жировой запас, поместив его в «клетки-капсулы». Активизировать вывод жира и сделать кожу гладкой, убрать апельсиновую корку поможет антицеллюлитный массаж.

                <br />

                В отличие от оздоровительного или спортивного массажа, во время процедуры прорабатываются не мышцы, а расположенный над ними подкожно-жировой слой. Механическое или аппаратное воздействие приводит к разрушению оболочек клеток, наполненных жиром, облегчая его выведение. Чтобы процедуры были более эффективными, их дополняют физическими нагрузками, при которых активно работают мышцы, расположенные на пораженных целлюлитом участках, внесением изменений в рацион, стимулирующими организм перейти от накопления жировых запасов к их сжиганию для получения энергии.
                </h2>

                <h3 className="title">ЭФФЕКТ МАССАЖА ОТ ЦЕЛЛЮЛИТА:</h3>
                <ul>
                    <li>способствует снижению веса из-за быстрого и эффективного выведения жира;</li>
                    <li>моделирует фигуру, делая ее более подтянутой;</li>
                    <li>уменьшает отеки – под действием рук массажиста,</li>
                    <li>уменьшает отеки – под действием рук массажиста, ультразвуковых волн, при вакуумных процедурах ускоряется отток лимфы, что уменьшает застойные явления;</li>
                    <li>улучшает циркуляцию крови, увеличивает количество питательных веществ и кислорода, получаемых клетками;</li>
                    <li>разрушает оболочки жировые клеток и ускоряет выведение жира;</li>
                    <li>активизирует обменные процессы, выработку эластина, коллагена;</li>
                    <li>разглаживает и тонизирует кожу, она становится более упругой;</li>
                    <li>ускоряет выведение токсинов – массажное воздействие благотворно влияет не только на участки тела, пораженные целлюлитом, но и улучшает общее состояние.</li>
                </ul>

                <h3 className="title">ВИДЫ ПРОЦЕДУР:</h3>
                <h2 className='anti'>
                Антицеллюлитный массаж может дополнять оздоровительный или выполняться отдельной процедурой. Он делается на участках тела, пораженных целлюлитом: бедрах, ягодицах, ногах, животе. Разрушить жировые клетки можно при ручном массажном воздействии – массажист чувствует каждый миллиметр тела, дозирует силу, интенсивность движений, похлопываний, щипков так, чтобы процедура была максимально эффективной.

                <br />

                Чтобы ускорить выведение токсинов и усилить антицеллюлитный эффект, делают медовый массаж. Перед сеансом на кожу наносится мед. Чтобы снять отеки, ускорить движение лимфы и выделение токсинов, расщепляемых жиров, делают лимфодренажный массаж. После курса из 7-10 процедур проявления целлюлита уменьшаются. Хороший антицеллюлитный эффект дают силиконовые банки. Они создают вакуум, воздействие которого делает кожу гладкой.
                </h2>

                <h3 className="title">ПОКАЗАНИЯ И ПРОТИВОПОКАЗАНИЯ</h3>
                <h2 className='anti'>
                Целлюлит – быстро прогрессирующее заболевание. Количество жировых отложений увеличивается, толщина клеточных оболочек утолщается. Запишитесь на массаж от целлюлита в салоне, обнаружив его первые признаки. Сделав недорого курс процедур, вы надолго сохраните гладкую здоровую кожу. Массажное воздействие будет эффективным на 2-4 стадиях, но потребуется большее количество сеансов, и стоимость антицеллюлитного массажа будет больше. Целлюлит поражает не только тучных людей, антицеллюлитная терапия часто нужна людям, не имеющим лишнего веса.

                <br />

                Массаж укрепляет здоровье, активизирует кровообращение, но он противопоказан людям, страдающим онкологическими заболеваниями, сахарным диабетом, варикозным расширением вен, при заболеваниях кожи, угревой сыпи, ранах и царапинах. Сообщите косметологу об имеющихся противопоказаниях, и он порекомендует другие эффективные и безопасные для вас антицеллюлитные процедуры.
                </h2>

                <h3 className="title">ПРЕИМУЩЕСТВА МАССАЖА В САЛОНЕ:</h3>
                <h2 className='anti'>В интернете есть рекомендации, видеоматериалы, как самостоятельно сделать антицеллюлитный массаж бедер, живота, ног. Кажется, что в этом нет ничего сложного, и узнав, сколько стоит сеанс в салоне, некоторые предпочитают делать его дома, самостоятельно или попросив членов семьи, подругу. Но процедуры в салоне более эффективные, безопасные и приятные:</h2>
                <ul>
                    <li>антицеллюлитный массаж делают специалисты – они умеют дозировать силу воздействия, не появляется синяков, что часто бывает после домашних массажных практик;</li>
                    <li>массаж – тяжелая физическая работа, когда вы делаете его сами, и хорошая релаксация, когда его делает массажист;</li>
                    <li>знание большого количества массажных приемов делает воздействие более эффективным;</li>
                    <li>использование антицеллюлитных гелей, кремов, обертываний профессиональных косметических линий.</li>
                </ul>

                <h3 className="title">ПРЕДЛОЖЕНИЕ В СПА-САЛОНЕ КРАСОТЫ «Академия Спа»:</h3>
                <h2 className='anti'>
                Обратитесь в салоны «СПА Академия» и выберите, где сделать ручной, медовый лимфодренажный, баночный антицеллюлитный массаж. Салоны работают в разных районах Волгограда.
                <br />
                <br />
                Выберите услугу, мастера и запишитесь на антицеллюлитный курс. При онлайн-записи цена на 20% ниже. Если вы впервые делаете антицеллюлитные процедуры, и не знаете, какое массажное воздействие эффективней – проконсультируйтесь с нашими специалистами. Они порекомендуют вам антицеллюлитную терапию с учетом возраста, веса, стадии целлюлита, возможных противопоказаний.
                </h2>
            </main>

            {products.map((product) => (
                <div key={product.id} className="btn-block">

                    <button className="btn-block" style={{border: 0, cursor: "pointer"}}  onClick={() => handleAddToCartWithNotification(product)}>Записаться →</button>
                </div>
            ))}
        </div>
    );
}

export default massageanti;